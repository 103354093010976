<template>
  <div class="notifications">
    <h1 class="notifications__title">{{ $t('notifications') }}</h1>
    <ui-loader v-model="visibleLoader"/>
    <div v-if="!visibleLoader" class="notifications__info">
      <notification-card
          v-for="(item, key) in getNotifications"
          :key="key"
          :item="item"
          @readNotification="readNotification($event)"
      />
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {getFullDate} from "@/utils/general";
import NotificationCard from "@/components/template/notifications/NotificationCard.vue";
import UiLoader from "@/components/ui/UiLoader.vue";

export default {
  name: "Notifications",
  components: {UiLoader, NotificationCard},
  data () {
    return {
      getFullDate,
      visibleLoader: false
    }
  },
  computed: {
    ...mapGetters(['getNotifications', 'getUser'])
  },
  methods: {
    ...mapActions(['notifications', 'notificationsRead']),
    readNotification (item) {
      this.visibleLoader = true
      this.notificationsRead({
        notificationIds: [item.id]
      }).then(() => {
        if(item.orderId) {
          this.$router.push(`/work-requests/${item.orderId}`)
        } else if(item.bodyText.slice(0, 19) === 'Ваша роль отклонена' && this.getUser.status === 'Rejected') {
          this.$router.push(`/profile/edit-profile`)
        } else if(item.bodyText.includes('Поступил запрос на подтверждение роли')) {
          this.$router.push({
            path: '/members',
            query: {status: 'new'}
          })
        } else if(item.supportRequestId) {
          this.$router.push(`/admin/tech-support/${item.supportRequestId}`)
        } else {
          this.notifications().then(() => {
            this.visibleLoader = false
          })
        }
      })
    }
  },
  mounted () {
    this.visibleLoader = true
    this.notifications().then(() => {
      this.visibleLoader = false
    })
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  justify-content: space-between;
  max-width: 1237px;
  width: 100%;
  background: #F5F5F5;
  padding: 150px 50px 50px 50px;
  min-height: 100vh;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 104px 20px 50px 20px;
  }

  &__title {
    color: #1B1A1F;
    font-size: 20px;
    font-weight: 400;
  }

  &__info {
    width: 100%;
    max-width: 774px;
    padding: 40px 50px 70px 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    border-radius: 20px;
    background: #FFFFFF;
    height: fit-content;
  }
}
::v-deep .loader__backdrop {
  margin-right: 500px;

  @media (max-width: 768px) {
    margin-right: 0;
  }
}
</style>
