<template>
  <div class="notification__card-item item">
      <img src="@/assets/svg/logo/red.svg" alt=""/>
      <div class="item__wrap">
        <p @click="$emit('readNotification', item)" :class="['item__wrap-text', { error: !item.readAt }]">{{ item.bodyText }}</p>
        <p class="item__wrap-date">{{ getFullDate(item.createdAt) }}</p>
      </div>
  </div>
</template>

<script>
import {getFullDate} from "../../../utils/general";
import {mapActions} from "vuex";

export default {
  name: "NotificationCard",
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    ...mapActions(['notificationsRead', 'notifications']),
    getFullDate,
  },
}
</script>

<style lang="scss" scoped>
.item {
  display: flex;
  align-items: center;
  gap: 10px;

  img {
    width: 26px;
    height: 30px;
  }

  &__wrap {
    display: flex;
    flex-direction: column;
    gap: 5px;

    &-text {
      color: #1B1A1F;
      font-size: 16px;
      cursor: pointer;
    }

    .error {
      color: #CE2121 !important;
    }

    &-date {
      color: #9A9A9A;
      font-size: 12px;
    }
  }
}
</style>
